require('./bootstrap');
require('../assets/js/app');


import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

document.addEventListener('livewire:load', function () {

  var changedElementId = '';
  var cropper;
  var redirect = '';

  window.addEventListener('imageCroppingRequested', (e) => {

    changedElementId = e.detail.id;
    redirect = e.detail.redirect;
    readImageToCropUrl(document.getElementById(changedElementId), e.detail.media, e.detail.route, { 'width': e.detail.aspect_w, 'height': e.detail.aspect_h });
  });

  function readImageToCropUrl(input, media, route, aspectRatio) {

    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('#image-cropping-canvas').attr('src', e.target.result);
      };
      reader.readAsDataURL(input.files[0]);
      setTimeout(function () { initCropperJs(media, route, aspectRatio); }, 1000);
    }
  }

  function initCropperJs(media, route, aspectRatio) {

    $("#cropping-modal").show();
    window.scrollTo(0, 0);

    let image = document.getElementById('image-cropping-canvas');
    cropper = new Cropper(image, {
      dragMode: 'move',
      aspectRatio: aspectRatio.width / aspectRatio.height,
      autoCropArea: 0.70,
      restore: false,
      guides: false,
      center: true,
      highlight: true,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
    });
    // On save crop button clicked
    $("#saveCroppedImage").off('click').click(function () {
      //---------------- SEND IMAGE TO THE SERVER-------------------------
      cropper.getCroppedCanvas().toBlob(function (blob) {
        var formData = new FormData();
        formData.append('croppedImage', blob);
        formData.append('media', media);

        $.ajax(route, {
          headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
          },
          method: "POST",
          data: formData,
          processData: false,
          contentType: false,
          beforeSend: function () {
            $("#cancelCrop").attr('disabled', true);
            $("#saveCroppedImage").attr('disabled', true);
            $("#saveCroppedImage span").hide();
            $("#saveCroppedImage .loading-icon").show();
          },
          success: function (response) {
            setTimeout(function () {
              $("#" + changedElementId).attr('style', "background-image: url('" + response.url + "')");
              changedElementId = '';
              hideCroppingModal();
              $("#saveCroppedImage .loading-icon").hide();
              $("#saveCroppedImage span").show();
            }, 1500);

            if (redirect) {
              window.location.href = redirect;
            }

          },
          error: function () {
            hideCroppingModal();
            $("#saveCroppedImage .loading-icon").hide();
            $("#saveCroppedImage span").show();
          }
        });
      });
    });

    // On save crop button clicked
    $("#cancelCrop").click(function () {
      hideCroppingModal();
    });
    $("#zoomOutImage").click(function () {
      cropper.zoom(-0.1);
    });
    $("#zoomInImage").click(function () {
      cropper.zoom(0.1);
    });

    function hideCroppingModal() {
      $("#cropping-modal").hide();
      $("#cancelCrop").attr('disabled', false);
      $("#saveCroppedImage").attr('disabled', false);
      $('#image-cropping-canvas').attr('src', '#');

      cropper.destroy();
    }
  }
});




